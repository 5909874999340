<template>
  <v-dialog
    v-model="infoModal"
    width="80%"
    @click:outside="$emit('update:infoModal', false)"
  >
    <v-card :dark="$dark.get()" style="border-radius: 30px">
      <v-card-title> </v-card-title>
      <v-card-text class="modal">
        <div class="modal_top">
          <!-- блок с информацией -->
          <div class="modal_text">
            <div>
              <div class="modal__item">
                <span class="modal_title">Название товара: </span
                >{{ redactingObj.providerProductId.name }}
              </div>
              <div class="modal__item">
                <span class="modal_title">Короткое описание: </span>
                {{ redactingObj.providerProductId.shortDescription }}
              </div>
              <div class="modal__item">
                <span class="modal_title">Полное описание: </span>
                {{ redactingObj.providerProductId.description }}
              </div>
              <div class="modal__item">
                <span class="modal_title">Guid: </span>
                {{ redactingObj.providerProductId.guid }}
              </div>
              <div class="modal__item">
                <span class="modal_title">Штрихкоды: </span>
                <span
                  v-for="barcode in redactingObj.providerProductId.barcodes"
                  :key="barcode.barcode"
                >
                  {{ barcode.barcode }}
                </span>
              </div>
            </div>

            <div class="modal_bot">
              <span class="modal_title">Информация по филиалам</span>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="table_title">Филиал</th>
                    <th class="table_title">Остаток</th>
                    <th class="table_title">Цена</th>
                    <th class="table_title">Со скидкой</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="departmentObj in redactingObj.prices"
                    :key="departmentObj.id"
                  >
                    <td>{{ departmentObj.departmentId }}</td>
                    <td>{{ departmentObj.balance }}</td>
                    <td>{{ departmentObj.price }}</td>
                    <td>{{ departmentObj.discount ? "Да" : "Нет" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
          <!-- блок с картинкой -->
          <div class="modal_image">
            <img
              v-if="redactingObj.providerProductId.images.length"
              :src="redactingObj.providerProductId.images[0].fileUrl"
              class="modal_img"
            />
            <p v-else>Нет фото</p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="flex justify-center">
        <v-btn
          small
          class="pr-10 pl-10"
          @click="$emit('update:infoModal', false)"
          >Закрыть</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoDialog",
  props: {
    infoModal: {
      type: Boolean,
      default: false,
    },
    redactingObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  padding: 20px;
  @media (max-width: 500px) {
    padding: 5px;
  }
  &_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    @media (max-width: 500px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  &_bot {
    width: 100%;
  }
  &_image {
    width: 230px;
    height: 230px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_img {
    max-height: 200px;
    max-width: 200px;
  }
  &_text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_title {
    color: black;
    font-weight: 700;
  }
}
.table_title {
  color: #5d4bd0 !important;
  font-weight: bold !important;
}
</style>
